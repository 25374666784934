
import { __ } from '@/libraries/lang';
import Vue from 'vue';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-avataaars-sprites';

export default Vue.extend({
    methods: {
        __
    },
    data() {
        return {
            svg: '',
        }
    },
    mounted() {
        this.svg = createAvatar(style, {
            seed: this.displayName,
            // ... and other options
        });
    },
    computed: {
        avatarUrl() {
            return this.url.length === 0 ? '' : this.url;
        }
    },
    props: [ 'url', 'display-name' ]
})
